
import { computed, defineComponent, PropType, ref } from 'vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { NotificationsRestrictions } from '@/models/Interfaces';

export default defineComponent({
    props: {
        productId: {
            type: String,
            required: true
        },
        restrictions: {
            type: Object as PropType<NotificationsRestrictions>,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    setup(props: any) {
        console.log(props.restrictions);
        const notificationsRestrictions = ref<NotificationsRestrictions>(props.restrictions);

        const unfinishedProductsNotificationsToggle = computed(() => {
            if (
                notificationsRestrictions.value.unfinishedProductSlotEmpty == true ||
                notificationsRestrictions.value.unfinishedProductPriceEmpty == true ||
                notificationsRestrictions.value.unfinishedProductSlotLess == true ||
                notificationsRestrictions.value.unfinishedProductPriceLess == true ||
                notificationsRestrictions.value.unfinishedProductNoPhoto == true
            ) {
                return true;
            }
            return false;
        });

        const activateDeactivateNotificationRestrictions = async () => {
            console.log(notificationsRestrictions.value);
            swal.showLoading();
            setTimeout(async () => {
                const response = await api.activateDeactivateProductNotificationRestriction(notificationsRestrictions.value);

                if (response.error) {
                    swal.fire({
                        icon: 'error',
                        text: response.errorMessage
                    });
                }
            }, 500);
            swal.close();
        };

        const activateDeactivateUnfinishedGroupNotifications = async (status: boolean) => {
            notificationsRestrictions.value.unfinishedProductSlotEmpty = status;
            notificationsRestrictions.value.unfinishedProductPriceEmpty = status;
            notificationsRestrictions.value.unfinishedProductSlotLess = status;
            notificationsRestrictions.value.unfinishedProductPriceLess = status;
            notificationsRestrictions.value.unfinishedProductNoPhoto = status;

            await activateDeactivateNotificationRestrictions();
        };

        return {
            unfinishedProductsNotificationsToggle,
            notificationsRestrictions,
            activateDeactivateNotificationRestrictions,
            activateDeactivateUnfinishedGroupNotifications
        };
    }
});
