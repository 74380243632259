
import { defineComponent, ref } from 'vue';
import ProductDetailsComponent from '@/components/ProductDetailsComponent.vue';

export default defineComponent({
    name: 'product',
    props: {
        id: {
            type: String,
            required: true
        },
        tab: {
            type: Number,
            default: 1
        }
    },
    components: { ProductDetailsComponent },
    async setup() {
        return {};
    }
});
