
import { defineComponent, PropType, ref } from 'vue';
import WeekdaysSelect from '@/components/Calendar/WeekdaysSelect.vue';
import Calendar from 'primevue/calendar';
import swal from 'sweetalert2';
import TimeInput from '@/components/TimeInput.vue';
import { CalendarSlot } from '@/models/Calendar';
import mixin from '@/mixin';
import Q from 'q';

class SlotsFilter {
    startDate: Date | null = null;
    endDate: Date | null = null;
    from = '';
    to = '';
    daysOfWeek: string[] = [];
}

export default defineComponent({
    props: {
        showFilter: {
            type: Boolean,
            default: false
        },
        slots: {
            type: Array as PropType<CalendarSlot[]>,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    components: { WeekdaysSelect, TimeInput, Calendar },
    emits: ['revert', 'open'],
    data() {
        return {};
    },
    async setup(props, { emit }: any) {
        const filter = ref(new SlotsFilter());
        const filteredSlots = ref<null | CalendarSlot[]>(null);
        function onClear() {
            filter.value = new SlotsFilter();
            filteredSlots.value = null;
        }
        function overlaps(range1: { start: number; end: number }, range2: { start: number; end: number }) {
            //( [ ] ) and ( [ ] )
            if (range1.start < range1.end && range2.start < range2.end) {
                if (range2.end < range1.start) return false;
                if (range1.end < range2.start) return false;
            } else if (range1.start > range1.end) {
                //( ] [ ) and ( ] [ )
                if (range2.start > range2.end) {
                    return true;
                }
                //( ] [ ) and ( [ ] )
                if (range1.start < range2.start && range1.end > range2.end) {
                    return false;
                }
            }
            //( [ ] ) and ( ] [ )
            else if (range2.start > range2.end) {
                if (range1.start > range2.start && range1.end < range2.end) {
                    return false;
                }
            }
            return true;
        }
        async function onFilter() {
            swal.showLoading();
            let result = props.slots;
            const start = filter.value.startDate;
            const end = filter.value.endDate;
            if (start) {
                result = result.filter(x => new Date(x.repeatEndDate || x.startDate) >= start);
            }
            if (end) {
                result = result.filter(x => new Date(x.startDate) <= end);
            }
            if (filter.value.daysOfWeek.length) {
                result = result.filter(x => x.daysOfWeek.find(d => filter.value.daysOfWeek.includes(d)));
            }
            if (filter.value.from || filter.value.to) {
                const range = {
                    start: filter.value.from ? mixin.methods.getMinutes(filter.value.from) : 0,
                    end: filter.value.to ? mixin.methods.getMinutes(filter.value.to) : 0
                };
                if (range.start != range.end) {
                    result = result.filter(x => {
                        if (x.startTime == x.endTime) return true;

                        const rangeSlot = {
                            start: mixin.methods.getMinutes(x.startTime),
                            end: mixin.methods.getMinutes(x.endTime)
                        };
                        return overlaps(range, rangeSlot);
                    });
                }
            }
            if (filter.value.to) {
                const to = mixin.methods.getMinutes(filter.value.to);
                result = result.filter(x => mixin.methods.getMinutes(x.startTime) <= to);
            }

            await Q.delay(550);
            filteredSlots.value = result;
            swal.close();
        }
        function showAll() {
            filter.value = new SlotsFilter();
            onFilter();
        }
        function onEdit(slot: CalendarSlot) {
            emit('open', CalendarSlot.clone(slot));
        }
        return { filter, filteredSlots, onFilter, onClear, onEdit, showAll };
    }
});
